import React, { useState } from 'react';
import '../styles/ImportFiles.css';
import '../../common/styles/SelectCustom.css';

export default function StorageSourceList(props) {

    const [selectedSourceID, setSelectedSourceID] = useState({
        cloudConfigurationID: "-1",
        connectionName: "Local",
        connectionType: "local",
        connectionTypeName: "Local",
        defaultSelect: "true",
        displayName: "Local"
    });

    const handleSelectChange = (event) => {
        setSelectedSourceID(event.target.value);
        props.onChange(parseInt(event.target.value));
    }

    return (
        <div className='destination-select'>
            <select onChange={(event) => handleSelectChange(event)} className={props.className ? props.className : 'import-files-select'} value={selectedSourceID.displayName} defaultValue={selectedSourceID.displayName} >
                {props.listData && props.listData.length && props.listData.map((item, key) => {
                    return <option selected={true} key={key} value={item.cloudConfigurationID}>{item.displayName}</option>
                })}
            </select>
            <span className="select-bar"></span>
        </div>
    );
}