import React from 'react';
import ReactToolTip from 'react-tooltip';
import PropTypes from 'prop-types';
import '../styles/PlainTextData.css'

/**
 * Idependent Component used to create a tool tip for text provided
 * @param {String} props.value - The value on which react tooltip would work
 * @param {String} props.suffix - Optional field for displaying the suffix for text feild
 * @param {String} props.classname - Optional field for adding additional css to the component
 */
function PlainTextData(props) {
    let value = props.subValue ? props.subValue : props.value;
    return(
        <div className='pt-div'>
            <p
                className={`pt-text ${props.classname}`}
                data-tip={value}
                data-delay-show='1000'
                data-for={value}>
                {props.suffix}{props.value}
            </p>
            <ReactToolTip 
                place='right'
                effect='solid'
                id={value}/>
        </div>
    );
}

PlainTextData.propTypes ={
    value: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    classname: PropTypes.string,
}

export default PlainTextData;