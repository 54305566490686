import React, { useState,useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { ZuiUserMenuButton } from '@zeiss/zui-react';
import { ZuiHeaderbar, ZuiIconZeiss, ZuiIconLogout } from "@zeiss/zui-react";
import '../styles/Header.css';
import ReactToolTip from 'react-tooltip';
import { GetAndUpdateUserLoginInfo, setConfig } from '../../utils/Config';
import Modal from 'react-bootstrap/Modal';
import { ZuiButton } from "@zeiss/zui-react";
import { config } from '../../utils/Config';
import { UpdateEmailNotifySetting } from '../api/HeaderAPI';
import { refreshAccessToken } from "../../utils/APIUtils";

function Header() {
    const { accounts } = useMsal();
    const { instance } = useMsal();
    const [userName, setUserName] = useState('');
    const [show, setShow] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(1);

    useEffect(async() => {
        if (accounts && accounts.length > 0) {
            let token =  await refreshAccessToken();
            if (token && token.accessToken) {
                window.localStorage.setItem('token', token.accessToken);
            }
            let role = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["med_data conversion service_role"];
            let email = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).eMail;
            let orgId = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["dataConversionService-OrganisationID"];
            let name = JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields)["dataConversionService-OrganisationName"];
            let userFirstName = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).firstName;
            let userLastName = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).lastName;
            let userAccountId = JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).accountId;
            setUserName(JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).eMail);
            window.localStorage.setItem('email', JSON.parse(accounts[0].idTokenClaims.ZeissIdBase).eMail);
            setConfig('organizationID' , parseInt(orgId));
            setConfig('organizationName' , name);
            localStorage.setItem('organizationID', orgId);
            localStorage.setItem('organizationName', name);
            setConfig('userFirstName' , userFirstName);
            setConfig('userLastName' , userLastName);
            setConfig('userAccountId' , userAccountId);
            localStorage.setItem('userFirstName', userFirstName);
            localStorage.setItem('userLastName', userLastName);
            localStorage.setItem('userAccountId', userAccountId);
            setConfig('email' , email);
            setConfig('role' , role);

            if(role && email && orgId) {
                let param =  {
                    organizationID: orgId,
                    emailAddress: email,
                    role: role,
                    comment: 'login success'
                };
                let usersData = await GetAndUpdateUserLoginInfo(param);
                if(usersData.responseCode === 200) {
                    setSelectedNotification(usersData.userInfo.isNotify);
                }
            }
        }
    }, [accounts]);

    const logoutAction = () => {
        const logoutRequest = {
            postLogoutRedirectUri: "https://iconvert-dev.zeiss.com/"
        }
        instance.logoutRedirect(logoutRequest);
    };

    const handleClose = () => setShow(false);

    const handleShow = () => {
        setShow(true);
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSelectedNotification(parseInt(value));
    };

    const handleSaveAction = async() => {
        let param =  {
            organizationID: config.organizationID,
            userID: config.userId,
            isNotify: selectedNotification,
            modifiedBy: config.userId
        };
        let notifyData = await UpdateEmailNotifySetting(param);
        if(notifyData && notifyData.responseCode === 200) {
            setShow(false);
        }
    }

    return(
        <>
            <Modal show={show} onHide={handleClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='email-notification-title'>Email Notification</div>       
                    <div>Send me a notification when:</div>    
                    <div className='input-container'>
                        <div className='email-radio-container'>
                            <label className='admin-radio'>
                                <input type="radio"
                                    value={1}
                                    className='input-radio'
                                    defaultChecked={selectedNotification}
                                    checked={selectedNotification == 1}
                                    onChange={(event) => handleInputChange(event)}
                                />
                                When a job is initiated and completed
                            </label> 
                            <label>
                                <input type="radio"
                                    value={2}
                                    className='input-radio'
                                    checked={selectedNotification == 2}
                                    defaultChecked={selectedNotification}
                                    onChange={(event) => handleInputChange(event)}
                                />
                                When a job is completed
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value={0}
                                    className='input-radio'
                                    checked={selectedNotification == 0}
                                    defaultChecked={selectedNotification}
                                    onChange={(event) => handleInputChange(event)}
                                />
                                Never
                            </label>
                        </div>
                    </div>       
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={handleSaveAction}>Save</ZuiButton>
                </Modal.Footer>
            </Modal>
            <div className='header-container'>
                <ZuiHeaderbar>
                    <ZuiIconZeiss size="xl" slot="icon"></ZuiIconZeiss>
                    <ZuiUserMenuButton  onClick={() => handleShow()} size="l" userName={userName} slot="userMenuButton">
                        {/* <ZuiAvatar initials='TU' slot="avatar"></ZuiAvatar> */}
                    </ZuiUserMenuButton>
                </ZuiHeaderbar>
                <div data-tip='Logout' data-for="registerTip">
                    <ZuiIconLogout style={{cursor: 'pointer'}} size='l' onClick={() => logoutAction()} />
                </div>
                <ReactToolTip 
                    place='bottom'
                    effect='solid'
                    id="registerTip">
                </ReactToolTip>
            </div>
        </>
    );
}

export default Header;