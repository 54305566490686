import { APIUtils } from './APIUtils';
import { getAppInsights } from '../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export let config = {};

export const setConfig = (key, value) => {
    config[key] = value;
}

export async function GetAndUpdateUserLoginInfo(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        emailAddress: param.emailAddress,
        role: param.role,
        comment: param.comment
    }

    await APIUtils('User/GetAndUpdateUserLoginInfo').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.userInfo = result.data.result;
            config.email = result.data.result.emailAddress;
            config.userId = result.data.result.userID;
            config.userFirstName = result.data.result.firstName;
            config.userLastName = result.data.result.lastName;
            config.orgId = result.data.result.organizationID;
            window.localStorage.setItem('email', result.data.result.emailAddress);
            window.localStorage.setItem('userId', result.data.result.userID);
            appInsights.trackTrace({ message: 'GetAndUpdateUserLoginInfo', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetAndUpdateUserLoginInfo' } });
            }
        });
    return responseData;
}