import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/SortingHeader.css';
import {ZuiIconArrowFilledArrowFilledDown, ZuiIconArrowFilledArrowFilledUp} from '@zeiss/zui-react';

/**
 * Component for Table Header which allows sorting feature.
 * @param {String} props.customCss - Custom css which could be passed from parent component
 * @param {Boolean} props.disable - Display a loading icon and block sorting feature if disabled.
 * @param {String} props.id - Unique id for the header identification
 * @param {Function} props.onHeaderClick - callback to let parent know a click event is triggered for sort
 * @param {Boolean} props.sortAscending - Tells the sorting order Ascending(true) Descending(false)
 * @param {String} props.sortBy - Existing state of sort order for clickevent toggle, if required.
 * @param {Boolean} props.sortHeader - Does the header allow sorting.
 * @param {String} props.subTitle - Sub-Title of the Table Header
 * @param {String} props.title - Title of the Table Header
 * TODO - This component has the potential to become a Util.
 */
export const SortingHeader = (props) => {

    const [sortAscending, setSortAscending] = useState(true);
    const [isSelected, setIsSelected] = useState(false);

    //Evaluated if the header is in selected or unselected state
    useEffect(() => {
        setIsSelected(props.id === props.sortBy);
        setSortAscending(props.sortAscending);
    }, [props.flagCount, props.id, props.sortBy, props.sortAscending]);

    /**
     * Function to handle user action w.r.t sorting 
     * to a table header.
     * @param {String} id - Unique columnID 
     */
    const onClick = (id) => {
        if (props.disable) return;
        props.onHeaderClick(id, (id === props.sortBy) ? !sortAscending: true);
    }

    return (
        <>
            {!props.sortHeader && (
                <th className={props.customCss}>{props.title}</th>
            )}
            {props.sortHeader && (
                <th className={`${props.customCss} ${isSelected ? 'header-selected' : ''} ${props.disable ? 'cursor-progress' : 'cursor-pointer'}`} onClick={() => onClick(props.id)}>
                    <div className='flex-center'>
                        <div className='align-self-center'>
                            <div className='table-header'>
                                <div className='align-self-center'>
                                    <div data-testid="title">{props.title}</div>
                                    <div data-testid="sub-title" className='sub-title'>{props.subTitle}</div>
                                </div>
                            </div>
                        </div>
                        <div className='align-self-center margin-left-5 margin-top-5'>
                            {
                                isSelected ? 
                                    sortAscending ? <ZuiIconArrowFilledArrowFilledUp className='selected-caret' size='s'/> : <ZuiIconArrowFilledArrowFilledDown className='selected-caret' size='s'/> 
                                    : <ZuiIconArrowFilledArrowFilledUp className='unselected-caret' size='s'/>
                            }
                        </div>
                    </div>
                </th>
            )}

        </>
    );
}

SortingHeader.propTypes = {
    customCss: PropTypes.string,
    disable: PropTypes.bool,
    flagCount: PropTypes.number,
    id: PropTypes.string,
    onHeaderClick: PropTypes.func,
    sortAscending: PropTypes.bool,
    sortBy: PropTypes.string,
    sortHeader: PropTypes.bool.isRequired,
    subTitle: PropTypes.string,
    title: PropTypes.string,
}