import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function GetSASToken(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        duration: param.duration,
    }

    await APIUtils('/api/Common/GetSaSToken').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.result = result.data.result;
            appInsights.trackTrace({ message: 'GetSASToken', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetSaSToken' } });
            }
        });
    return responseData;
}

export async function ImportFile(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        sourceConnectionID: param.sourceConnectionID,
        destinationConnectionID: param.destinationConnectionID,
        createdBy: param.createdBy,
        isFileCountMoreThanThousand:param.isFileCountMoreThanThousandTemp,
        lstJobFile: param.lstJobFile
    }

    await APIUtils('/api/Job/ImportJob').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.result = result.data.result;
            appInsights.trackTrace({ message: 'ImportFile', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'ImportFile' } });
            }
        });
    return responseData;
}


export async function GetCloudConfigurationList(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        pageNo: 1,
        pageSize:10000
    }

    await APIUtils('/CloudConfiguration/GetCloudConfiguration').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.result = result.data.result;
            appInsights.trackTrace({ message: 'GetCloudConfiguration', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetCloudConfigurationList' } });
            }
        });
    return responseData;
}

export async function GetCloudStorageFilesList(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        cloudConfigurationID: param.cloudConfigurationID
    }

    await APIUtils('/CloudStorageAccess/GetCloudStorageFilesList').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.result = result.data.result;
            appInsights.trackTrace({ message: 'GetCloudStorageFilesList', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetCloudStorageFilesList' } });
            }
        });
    return responseData;
}