import React, { useState,useEffect, useRef } from 'react';
import { SortingHeader } from '../../common/components/SortingHeader';
import '../styles/UserList.css';
import PlainTextData from '../../common/components/PlainTextData';
import { ZuiIconEditEditEdit, ZuiIconDelete } from '@zeiss/zui-react';
import { GetUsersAPI } from '../api/UserAPI';
import ReactToolTip from 'react-tooltip';

export default function UserList(props) {
    const [allUsersData, setAllUsersData] = useState(props.usersData);
    const [sortBy, setSortBy] = useState('name');
    const [disableSort, setDisableSort] = useState(false);
    const [sortAscending, setsortAscending] = useState(true);

    const arBodyContentRef = useRef(null);

    const onScroll = () => {
        props.setScrollLoading(false);
        let scrollBottom = arBodyContentRef.current.scrollHeight - arBodyContentRef.current.scrollTop - arBodyContentRef.current.clientHeight;
        scrollBottom = Math.floor(Math.abs(scrollBottom)) <= 0;
        console.log('scrollHeight*******', arBodyContentRef.current.scrollHeight);
        console.log('scrollTop*******', arBodyContentRef.current.scrollTop);
        console.log('clientHeight*******', arBodyContentRef.current.clientHeight);
        console.log('scrollBottom************', scrollBottom);
        if (scrollBottom)  {
            props.setScrollLoading(true);
            loadMoreExams();
        }
    }

    function loadMoreExams() {
        console.log('loadMoreExams**************',allUsersData.length);
        console.log(props.totalResults);
        if (allUsersData.length >= props.totalResults) {
            props.setScrollLoading(false);
            return;
        } 
        if(allUsersData.length == 0) return;
        props.setPage(props.page + 1);
        (async () => { 
            await props.fetchMoreListItems(props.page + 1, false); 
        })();
    }


    useEffect(() => {
        setAllUsersData(props.usersData);
    }, [props.usersData]);

    /**
     * Function for user action on table header for sorting the tenants data.
     * @param {String} sort - Unique column id for sorting By 
     * @param {Boolean} isOrderAscending  - Boolean value true(asc), false(desc)
     */
    const onHeaderClick = async (sort, isOrderAscending) => {
        let order = isOrderAscending ? 'asc' : 'desc';
        let param = { 
            sort, 
            order,
            organizationID: props.selectedOrgAllDetails[0].zeissRefOrganizationID,
            pageNo: props.page,
            pageSize: 60,
        };
        
        setDisableSort(true);
        let userData = await GetUsersAPI(param);
        setAllUsersData(userData.users);
        setSortBy(sort);
        setsortAscending(isOrderAscending);
        setDisableSort(false);
    }

    const sortFeatures = {
        sortHeader: true,
        sortBy,
        sortAscending,
        disable: disableSort,
        onHeaderClick: (id, order) => onHeaderClick(id, order)
    };

    const getUserNameWithAsterisk = (user) => {
        let userWithTooltip = '';
        if(user.zeissRefInvitationState == 1 && user.zeissRefApplicationPermissionState == 2 ) {
            return userWithTooltip;

        } else {
            if(user.zeissRefInvitationState === 0) {
                userWithTooltip = user.name + ' (Invitation Pending';
                // return userWithTooltip;
            } 
            if(user.zeissRefInvitationState === 1) {
                userWithTooltip = user.name + ' (Invitation Accepted';
                //return userWithTooltip;
            } 
            if(user.zeissRefInvitationState === 2) {
                userWithTooltip = user.name + ' (Invitation Expired';
                //return userWithTooltip;
            } 
            if(user.zeissRefInvitationState === 3) {
                userWithTooltip = user.name + ' (Invitation Scheduled';
                //return userWithTooltip;
            } 

            if(user.zeissRefApplicationPermissionState === 0) {
                userWithTooltip += ', Application Permission State Inactive)';
            }

            if(user.zeissRefApplicationPermissionState === 1) {
                userWithTooltip += ', Application Permission State Draft)';
            }

            return userWithTooltip;
        }
    };

    const getDisplayRole = (user) => {
        if(user.roleID == 2) {
            return 'Non Admin';
        }
        else if(user.roleID == 3) {
            return 'Super Admin';
        } else {
            return 'Admin';
        }
    }

    return (
        <div>
            <table className='tabel tenant-list-table' aria-labelledby='tabelLabel'>
                <thead className='tenants-list-info-header'>
                    <tr>
                        <SortingHeader customCss='crd-table-patient-id' {...sortFeatures} id='name' title='Name' />
                        <SortingHeader customCss='crd-table-patient-name' {...sortFeatures} id='emailAddress' title='Email' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='roleID' title='Role' />
                        <SortingHeader customCss='job-status-table-edit-icon-size' sortHeader={false} title='Action' />
                    </tr>
                </thead>
                <tbody ref={arBodyContentRef} onScroll={onScroll} className='config-body'>
                    {allUsersData && allUsersData.length > 0 ? (allUsersData).map((user) => {
                        const userName = getUserNameWithAsterisk(user);
                        const userDisplayName = (user.zeissRefInvitationState !== 1 || user.zeissRefApplicationPermissionState !== 2) ? (user.name + '*') : user.name;
                        const displayRole = getDisplayRole(user);
                        return (
                            <React.Fragment key={`tr-${user.userName}`}>
                                <tr className="crd-table-row job-row">
                                    <td className='crd-table-patient-id'>
                                        <PlainTextData value={userDisplayName} subValue={userName} />
                                    </td>
                                    <td className='crd-table-patient-name'>
                                        <PlainTextData value={user.emailAddress} subValue={user.emailAddress}/>
                                    </td>
                                    <td className='crd-table-diagnosis'>
                                        <PlainTextData value={displayRole} subValue={displayRole}/>
                                    </td>
                                    <td className='crd-table-edit-icon-size'>
                                        <div style={{display: 'flex'}}>
                                            {user.zeissRefInvitationState == 1 ? 
                                                <div className="edit-action" data-tip='Edit User' data-for="registerTip">
                                                    <ZuiIconEditEditEdit  onClick={() => props.onClick(user, 'Edit')} />
                                                </div>
                                                : ''}
                                            {user.zeissRefInvitationState == 1 || user.zeissRefInvitationState == 2 ? 
                                                <div data-tip='Delete User' data-for="registerTip" className={user.zeissRefInvitationState == 2 ? 'expired-user-delete' : ''}>
                                                    <ZuiIconDelete onClick={() => props.handleDeleteModalShow(user)} />
                                                </div>
                                                : ''}
                                            <ReactToolTip 
                                                place='bottom'
                                                effect='solid'
                                                id="registerTip">
                                            </ReactToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    }) : null}
                    {allUsersData && allUsersData.length == 0 && 
                        <div className='no-data'>
                            <span>No user found.</span>
                        </div>
                    }
                </tbody>
            </table>
            {props.scrollLoading && 
                <div className='loading-text'>
                    Loading data...
                </div>
            }
        </div>
    );
}