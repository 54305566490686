import axios from 'axios';
import { loginRequest } from "../authConfig";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "../authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

/**
 * This is util for all kepler API calls.
 * axios interceptor is used to append token for Authorization in the header.
 * @param {String} urlExtension - extension for the base url
 * @returns {Object} functions for API calls.
 * 
 */

export const refreshAccessToken = async () => {
    const account = msalInstance.getActiveAccount();
    if(account) {
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
    
        return response;
    }
};

export const APIUtils = (urlExtension) => {
    //fetches the JWT token from sessionStorage.
    // axios interceptor to append JWT token for all API invoked from application.
    axios.interceptors.request.use(async(config) => {
        let token =  window.localStorage.getItem('token');

        config.headers['Authorization'] = 'Bearer ' + token;
        config.headers['Content-Type'] = 'application/json';
        
        if(config.data && config.data.organizationID) {
            if(config.url.includes('Organization/DeleteOrganization')) {
                if(config.data && config.data.zeissRefOrganizationID) {
                    config.headers['X-Org-ID'] = config.data.zeissRefOrganizationID;
                }
            } else {
                config.headers['X-Org-ID'] = config.data.organizationID;
            }
        }
            
        return config;
    },
    error => {
        Promise.reject(error)
    });

    axios.interceptors.response.use(
        response => { return response; },
        error => {
            if (error.response.status === 403) {
                sessionStorage.clear();
                window.location.href = "/login";
            }
            return Promise.reject(error);
        });

    //appending baseURL with urlExtension, depending upon the call invoked
    const url = `${process.env.REACT_APP_BASE_URL_API}${urlExtension}`;

    return {
        getAll: () => axios.get(url),
        getOne: requestData => axios.get(url, { params: { ...requestData } }),
        postCall: requestData => axios.post(url, requestData),
    }
}