import React, { useEffect, useState } from 'react';
import UserList from '../components/UserList';
import Modal from 'react-bootstrap/Modal';
import { ZuiIconPlus,ZuiButton } from "@zeiss/zui-react"
import '../styles/Users.css';
import ReactToolTip from 'react-tooltip';
import { GetOrganizationAPI, GetUsersAPI, CreateUserAPI, DeleteUserAPI, GetAndUpdateUsersAPI } from '../api/UserAPI';
import { refreshAccessToken } from "../../utils/APIUtils";
import { config } from '../../utils/Config';
import '../../common/styles/SelectCustom.css';

export const Users = () => {
    const PAGE_NUMBER = 1;
    const [show, setShow] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [error, setError] = useState('');
    const [isFormError, setIsFormError] = useState('');
    const [mode, setMode] = useState('Create');
    const [selectedUser, setselectedUser] = useState({
        organizationName: '',
        roleID: "2",
        firstName: '',
        lastName: '',
        emailAddress: ''
    });
    const [usersData, setUsersData] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [selectedOrgAllDetails, setSelecetdOrgAllDetails] = useState([]);
    const [page, setPage] = useState(PAGE_NUMBER);
    const [totalResults, setTotalResults] = useState(0);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [showReactivateModal, setReactivateModal] = useState(false);
    let userRole = window.localStorage.getItem('role');

    let sortCRD =  {
        sort: 'name',
        order: 'asc'
    };

    const getOrganizationsByUserRole = (org) => {
        if(userRole === 'Zeiss-Admin') {
            if(org.dbStatus === 2) {
                if(config && config.organizationID) {
                    if(org.zeissRefOrganizationID === parseInt(config.organizationID)) {
                        return org;
                    }
                }
            }
        }
    }

    useEffect(async () => {
        let organizations;
        if(userRole === 'Zeiss-Admin') { 
            setError('');
            let token =  await refreshAccessToken();
            if (token && token.accessToken) {
                window.localStorage.setItem('token', token.accessToken);
            }

            let orgSortCRD =  {
                sort: 'organizationName',
                order: 'asc'
            };
            let organizationData = await GetOrganizationAPI(orgSortCRD);
            if(organizationData.responseCode === 200) {
                organizations = organizationData.organizations.filter((org) => {
                    let data = getOrganizationsByUserRole(org);
                    return data;
                })
                if(userRole === 'Zeiss-Admin') { 
                    setOrganizationsData(organizationData.organizations);
                }
                
                setSelectedOrganization(organizations[0]);
                setSelecetdOrgAllDetails(organizations);
            } 
        } else {
            setError('');
            let token =  await refreshAccessToken();
            if (token && token.accessToken) {
                window.localStorage.setItem('token', token.accessToken);
            }
            let orgID = localStorage.getItem('organizationID');
            let name = localStorage.getItem('organizationName');
            if((config && config.organizationID) || orgID) {
                organizations = [{organizationName: config.organizationName || name , zeissRefOrganizationID: config.organizationID || orgID}];
                setOrganizationsData(organizations);
                setSelectedOrganization(organizations[0]);
                setSelecetdOrgAllDetails(organizations);
            }
        }

        let param = { 
            ...sortCRD,
            organizationID: organizations[0] && organizations[0].zeissRefOrganizationID,
            pageNo: page,
            pageSize: 60,
        };
        let userDataUpdate = await GetAndUpdateUsersAPI(param);
        console.log(userDataUpdate);
        let userData = await GetUsersAPI(param);
        if(userData.users)
            setUsersData(userData.users);
        setTotalResults(userData.totalResults);
        
    }, []);
    
    const handleClose = () => setShow(false);
    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleReativateClose = () => setReactivateModal(false);

    const handleShow = (selectedUser, type) => {
        setError('');
        setShow(true);
        setMode(type);
        if(type === 'Create') {
            let tempOrgDetails = {
                organizationName: '',
                roleID: '2',
                firstName: '',
                lastName: '',
                emailAddress: ''
            }
            setselectedUser(tempOrgDetails);
        } else {
            setselectedUser(selectedUser);
        }
    }

    const handleDeleteModalShow = (selectedUser) => {
        setError('');
        setShowDeleteModal(true);
        setselectedUser(selectedUser);
    }

    const validateForm = () => {
        let tempError = true;
        if(selectedUser.firstName === '') {
            let tempErrorMsg = 'Please enter first name';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        } 

        if(selectedUser.firstName) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedUser.firstName)) {
                setError('Please enter valid first name');
                setIsFormError(true);
                tempError = false;
                return;
            }
        }

        if(selectedUser.lastName === '') {
            let tempErrorMsg = 'Please enter last name';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        }

        if(selectedUser.lastName) {
            let pattern = new RegExp(/^[a-z\d\-_.,\s]+$/i);
            if (!pattern.test(selectedUser.lastName)) {
                setError('Please enter valid last name');
                setIsFormError(true);
                tempError = false;
                return;
            }
        } 

        if (selectedUser.emailAddress === '') {
            let tempErrorMsg = 'Please enter email address';
            setError(tempErrorMsg);
            setIsFormError(true);
            tempError = false;
            return;
        } else {
            setIsFormError(false);
            tempError = true; 
        } 
        if(selectedUser.emailAddress) {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(selectedUser.emailAddress)) {
                setError('Please enter valid email address');
                setIsFormError(true);
                tempError = false;
                return;
            }
        }

        if(tempError) {
            console.log(isFormError);
            setIsFormError(false);
        }

        return tempError;
    }

    const createUser = async (reactivateState) => {
        let email = localStorage.getItem('email');
        let errRes = validateForm();
        if(errRes) {
            let param;
            if(mode === 'Create') {
                param = {
                    roleID: selectedUser.roleID,
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                    emailAddress: selectedUser.emailAddress,
                    organizationID: selectedOrgAllDetails[0] && selectedOrgAllDetails[0].zeissRefOrganizationID,
                    organizationName: selectedOrgAllDetails[0] && selectedOrgAllDetails[0].organizationName,
                    createdBy: email,
                    isReActivate: reactivateState
                };
            } else {
                param = {
                    userID: selectedUser.userID,
                    roleID: selectedUser.roleID,
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                    emailAddress: selectedUser.emailAddress,
                    organizationID: selectedOrgAllDetails[0] && selectedOrgAllDetails[0].zeissRefOrganizationID,
                    organizationName: selectedOrgAllDetails[0] && selectedOrgAllDetails[0].organizationName,
                    modifiedBy: email,
                    zeissRefAccountID: selectedUser.zeissRefAccountID,
                    isReActivate:false
                };
            }
            
    
            let responseData = await CreateUserAPI(param);
            if(responseData.data.statusCode === 200 || responseData.data.status === 200) {
                if(responseData.data.status === 'ERROR') {
                    if(mode === 'Create' && reactivateState) {
                        setReactivateModal(false);
                    }
                    if(mode === 'Create' && responseData.data.message === 'Account already assigned to client application.') {
                        if(responseData.data.result && responseData.data.result.isDeleted) {
                            setError('');
                            setReactivateModal(true);
                        } else {
                            setIsFormError(true);
                            setError(responseData.data.message);
                        }
                    } else {
                        setIsFormError(true);
                        setError(responseData.data.message);
                    }
                } else {
                    setShow(false);
                    setReactivateModal(false);
                    fetchMoreListItems(page, false);
                }
            } else {
                if(responseData.data.statusCode === 500) {
                    setIsFormError(true);
                    setError('Error - Kindly contact Support team for resolution');
                }
                else if(responseData.responseCode === 401) {
                    setIsFormError(true);
                    setError('Unauthorized');
                } else {
                    setIsFormError(true);
                    setError(responseData.data.title);
                }
            }
        }
    };

    const handleInputChange = (event, name) => {
        const value = event.target.value;
        setselectedUser({
            ...selectedUser,
            [name]: value
        });
    };

    const deleteOrganization = async () => {
        let email = localStorage.getItem('email');
        let param = {
            userID: selectedUser.userID,
            zeissRefAccountID: selectedUser.zeissRefInvitationState == 2 ? -1 : selectedUser.zeissRefAccountID,
            emailAddress: selectedUser.emailAddress,
            organizationID: selectedOrgAllDetails[0] && selectedOrgAllDetails[0].zeissRefOrganizationID,
            modifiedBy: email
        };
        let responseData = await DeleteUserAPI(param);
        if(responseData.data.statusCode == 200) {
            if(responseData.data.status === 'ERROR') {
                setError(responseData.data.message);
            } else {
                setShowDeleteModal(false);
                fetchMoreListItems(page, false);
            }
        } else {
            if(responseData.data.statusCode === 500) {
                setShowDeleteModal(true);
                setError('Error - Kindly contact Support team for resolution');
            }
            else if(responseData.responseCode === 401) {
                setShowDeleteModal(true);
                setError('Unauthorized');
            } else {
                setShowDeleteModal(true);
                setError(responseData.data.title);
            }
        }
    };

    const handleSelectChange = (event) => {
        console.log(event.target.value);
        fetchMoreListItems(1, event.target.value);
    };

    const fetchMoreListItems = async(pageno, orgName) => {
        let pageNumber = pageno ? pageno : page;
        let orgID;
        if(orgName) {
            let selectedOrg = organizationsData.filter((org) => {
                if(org.organizationName === orgName) {
                    return true;
                }
            });
            setSelecetdOrgAllDetails(selectedOrg);
            orgID = selectedOrg[0] && selectedOrg[0].zeissRefOrganizationID;
            setPage(1);
            setSelectedOrganization({ organizationName: orgName });
        } else {
            setScrollLoading(true);
            orgID = selectedOrgAllDetails[0] && selectedOrgAllDetails[0].zeissRefOrganizationID;
        }

        let param = { 
            sort: 'name',
            order: 'asc',
            organizationID: orgID,
            pageNo: pageNumber,
            pageSize: 60,
        };
        let userDataUpdateTemp = await GetAndUpdateUsersAPI(param);
        let userDataTemp = await GetUsersAPI(param);
        console.log(userDataUpdateTemp);
        setTotalResults(userDataTemp.totalResults);
        if(userDataTemp.responseCode === 200) {
            setTotalResults(userDataTemp.totalResults);
            setScrollLoading(false);
            if(userDataTemp.pageNo > 1) {
                let ids = new Set(usersData.map(d => d.userID));
                let mergedJobs = [...usersData, ...userDataTemp.users.filter(d => !ids.has(d.userID))];
                setUsersData(mergedJobs);
                setScrollLoading(false);
            } else {
                setUsersData(userDataTemp.users);
                setScrollLoading(false);
            }
        }
    }

    return (
        <>
            <Modal show={showDeleteModal} onHide={handleDeleteClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <p>Are you sure you want to delete {selectedUser.name} ?</p>
                    </div> 
                    {error ? <div className='error-message'>{error}</div> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleDeleteClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={deleteOrganization}>Delete</ZuiButton>
                </Modal.Footer>
            </Modal>
            <Modal show={showReactivateModal} onHide={handleReativateClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <p>Account already assigned to client application, Are you sure you want to reactivate account ?</p>
                    </div> 
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleReativateClose}>No</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={() => createUser(true)}>Yes</ZuiButton>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}  backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'Create' ? 'Register User' : 'Edit User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <div className='input-container'>
                            <label className='organization-label-text'>First Name: <span style={{color: 'red'}}>*</span></label>
                            <input type='text' onChange={(event) => handleInputChange(event, 'firstName')} value={selectedUser.firstName}/>
                        </div>
                        <div className='input-container'>
                            <label className='organization-label-text'>Last Name: <span style={{color: 'red'}}>*</span></label>
                            <input type='text' onChange={(event) => handleInputChange(event, 'lastName')} value={selectedUser.lastName}/>
                        </div>
                        <div className='input-container'>
                            <label className='user-label-text'>Email address: <span style={{color: 'red'}}>*</span></label>
                            <input type='text' disabled={mode === 'Create' ? false : true} onChange={(event) => handleInputChange(event, 'emailAddress')}  value={selectedUser.emailAddress} />
                        </div>
                        <div className='input-container'>
                            <label className='user-radio-text'>Role:</label>
                            <div style={{marginRight:10}}>
                                {selectedOrgAllDetails.length > 0 && selectedOrgAllDetails[0].zeissRefOrganizationID != process.env.REACT_APP_ORG_ID ? 
                                    <>
                                        <label className='admin-radio'>
                                            <input
                                                type="radio"
                                                value="1"
                                                className='input-radio'
                                                checked={selectedUser.roleID == "1"}
                                                onChange={(event) => handleInputChange(event, 'roleID')}
                                            />
                                            Admin
                                        </label> 
                                        <label>
                                            <input
                                                type="radio"
                                                value="2"
                                                className='input-radio'
                                                defaultChecked={selectedUser.roleID}
                                                checked={selectedUser.roleID == "2"}
                                                onChange={(event) => handleInputChange(event, 'roleID')}
                                            />
                                            Non Admin
                                        </label>
                                    </> :
                                    <label>
                                        <input
                                            type="radio"
                                            value="3"
                                            className='input-radio'
                                            defaultChecked={true}
                                            checked={selectedUser.roleID == "3"}
                                            onChange={(event) => handleInputChange(event, 'roleID')}
                                        />
                                        Super Admin
                                    </label>
                                }
                            </div>
                        </div>    
                    </div>
                    {error ? <div className='error-message'>{error}</div> : ''}
                </Modal.Body>
                <Modal.Footer>
                    <ZuiButton emphasis='active' onClick={handleClose}>Cancel</ZuiButton>
                    <ZuiButton emphasis='active-primary' onClick={() => createUser(false)}>Save</ZuiButton>
                </Modal.Footer>
            </Modal>
            <div className='tenants-main-container'>
                <div className='tenant-list-header'>
                    <div>
                        <p className='header-text'>User Management</p>
                    </div>
                    <div className='icon-container'>
                        <div className='wrap'>
                            <div className="select">
                                <select placeholder='Select User' className='select-organization-user select-text' onChange={(event) => handleSelectChange(event, 'organizationName')} defaultValue={selectedOrganization.organizationName} value={selectedOrganization.organizationName}>
                                    {organizationsData.map((e, key) => {
                                        return <option selected={true} key={key} value={e.organizationName}>{e.organizationName}</option>
                                    })}
                                </select>
                                <span className="select-bar"></span>
                            </div>
                        </div>
                        <div className='button-container' data-tip='Register User' data-for="registerTip">
                            <ZuiIconPlus size='l' onClick={() => handleShow(selectedUser, 'Create')} />
                            <ReactToolTip 
                                place='bottom'
                                effect='solid'
                                id="registerTip">
                            </ReactToolTip>
                        </div>
                    </div>
                </div>
                <UserList fetchMoreListItems={fetchMoreListItems}  scrollLoading={scrollLoading} setScrollLoading={setScrollLoading} totalResults={totalResults} page={page} setPage={setPage} selectedOrgAllDetails={selectedOrgAllDetails} usersData={usersData} onClick={handleShow} handleDeleteModalShow={handleDeleteModalShow}/>
            </div>
        </>
    )
}