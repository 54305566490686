import React from 'react';
import Sidemenu from "../components/Sidemenu";
import Header from "../components/Header";
import '../styles/Layout.css';

function LayoutSSO(props) {
    return (
        <div>
            {!props.notfound ? <div className='page-layout'>
                <Header/>
                <div className='side-menu-container'>
                    <Sidemenu role={props.role} history={props.history}/>
                    <div className='right-container'>
                        {props.children}
                    </div>
                </div>
            </div> :
                <div className='test'>{props.children}</div>
            }
        </div>
    );
}

export default LayoutSSO;