import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function UpdateEmailNotifySetting(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        userID: param.userID,
        isNotify: param.isNotify,
        modifiedBy: param.modifiedBy
    }

    await APIUtils('User/UpdateEmailNotifySetting').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.organizations = result.data.result;
            appInsights.trackTrace({ message: 'UpdateEmailNotifySetting', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'UpdateEmailNotifySetting' } });
            }
        });
    return responseData;
}
