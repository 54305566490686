import { BlobServiceClient } from '@azure/storage-blob';
const containerName = process.env.REACT_APP_BLOB_CONTAINER;
const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT; 

const createBlobInContainer = async (containerClient, file) => {
    let count = 0;
    let status;
    const blobClient = containerClient.getBlockBlobClient(file.name);

    await blobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: () => {
            // console.log(progress);
        },
        blobHTTPHeaders: { blobContentType: file.type }
    }).then(response => {
        if (response.errorCode != null) {
            console.log("failed")
            status = "fail";
        }
        status = "done";
        if(status === 'done') {
            count = count + 1;
        }
    })
    console.log(count);
    console.log(status);
    return count;
}

export const downloadFileFromBlob = async (sasToken, file) => {
    let response = {};
    const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
    );
    const containerClient = blobService.getContainerClient(containerName);
    const blobClient = containerClient.getBlockBlobClient(file);
    const downloadBlockBlobResponse = await blobClient.download(0);
    console.log(downloadBlockBlobResponse);
    response.status = 200;
    response.url = blobClient.url;
    return response;
}

export const uploadFileToBlob = async (sasToken, file, orgId) => {
    if (!file) return [];
    
    const blobService = new BlobServiceClient(
        `https://${storageAccountName}.blob.core.windows.net/${containerName}/${orgId}?${sasToken}`
    );
    const containerClient = blobService.getContainerClient(containerName);
    let url = containerClient.url.replace(`${orgId}/dicomfiles`, `${orgId}/Input`);
    containerClient.url = url;
    let tempCount = await createBlobInContainer(containerClient, file);
    return tempCount;
};