import React from 'react';
import { Link } from 'react-router-dom';
/**
 * 404 Error Page
 */
export const NotFound = () => {

    return (
        <>
            <div style={{textAlign: 'center', marginTop: 10 + '%'}}>
                <h1>401 - Unauthorized!</h1>
                <p>You dont have access to this page</p>
                <Link to="/">
                    Go Home
                </Link>
            </div>
        </>
    );
}