import React, { useEffect, useState } from 'react';
import { Routes } from '../router/Routes';
import { MsalProvider } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";

export const Router = ({pca}) => {
    const { accounts } = useMsal();
    let [role, setUserRole] = useState('');

    useEffect(() => {
        if (accounts.length > 0) {
            console.log(accounts);
            setUserRole(JSON.parse(accounts[0].idTokenClaims.ZeissIdCustomFields).firstName);
        }
    }, [accounts]);

    return (
        <main>
            <MsalProvider instance={pca}>
                <Routes role={role}></Routes>
            </MsalProvider>
        </main>
    );
}
