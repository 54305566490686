import React, { useState, useEffect } from 'react';
import '../styles/Sidemenu.css';
import {Link} from "react-router-dom";

const Sidemenu = (props) => {
    const [userRole, setRole] = useState(props.role);

    useEffect(() => {
        setRole(props.role);
    }, [props.role]);

    const SidebarItems = [
        {
            name: "Organization Management",
            route: '/organizations',
            role: ['Zeiss-Admin']
        },
        {
            name: "User Management",
            route: '/users',
            role: ['Zeiss-Admin', 'Tenant-Admin']
        },
        {
            name: "Cloud Storage Management",
            route: '/configuration',
            role: ['Zeiss-Admin', 'Tenant-Admin']
        },
        {
            name: "Import Files",
            route: '/import-files',
            role: ['Zeiss-Admin', 'Tenant-Admin', 'Tenant-Non-Admin']
        },
        {
            name: "Job Status",
            route: '/job-status',
            role: ['Zeiss-Admin','Tenant-Admin', 'Tenant-Non-Admin']
        }
    ];

    return (
        <div>
            <div className='menu-layout'>
                <div className="sidenav">
                    {
                        SidebarItems.filter((itemObj )=> {
                            if(itemObj.role.includes(userRole)) {
                                return itemObj;
                            }
                        }).map((item)=> {
                            return (
                                <Link key={item.name} to={item.route}>
                                    <div key={item.name} >
                                        <p style={{fontSize: 17}}>{item.name}</p>
                                    </div>
                                </Link>
                            );
                        })
                    }
                    <div className='research-text'>
                        <p>For Research Only</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Sidemenu;
