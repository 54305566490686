import React, { useState, useEffect } from 'react';
import ImportFilesList from '../components/ImportFilesList';
import StorageSourceList from '../components/StorageSourceList';
import { ZuiDivider } from "@zeiss/zui-react";
import '../styles/ImportFiles.css';
import { GetCloudConfigurationList, GetCloudStorageFilesList } from '../api/ImportFileAPI';
import { config } from '../../utils/Config';
import ReactToolTip from 'react-tooltip';
import {tokenData} from '../../router/Routes';
import { refreshAccessToken } from "../../utils/APIUtils";

export const ImportFiles = (props) => {
    const [selectedFileList, setSelectedFileList] = useState([]);
    const [fileTypeNotSupported, setFileTypeNotSelected] = useState(false);
    const [source, setSource] = useState(-1);
    const [destination, setDestination] = useState(-1);
    const [sourceID] = useState("-1");
    const [destinationID, setDestinationID] = useState("-1");
    const [sourceList, setSourceList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [fileProgress, setFileProgress] = useState(1);
    const [disableButton, setDisableButton] = useState(false);
    const [cloudSourceStorage, setCloudSourceStorage] = useState(false);
    const [convertTooltip, setConvertTooltip] = useState('');
    const [fileCount, setFileCount] = useState(false);
    const [selectAll, setSelectedAll] = useState(false);
    const [isFileCountMoreThanThousand, setIsFileCountMoreThanThousand] = useState(false);

    useEffect(async () => {
        setSelectedFileList([]);
        let token =  await refreshAccessToken();
        if (token && token.accessToken) {
            window.localStorage.setItem('token', token.accessToken);
        }
        let orgIdTemp = config && config.organizationID || tokenData.orgId || window.localStorage.setItem('organizationID');
        if(orgIdTemp) {
            let param = {
                organizationID: orgIdTemp
            };
            let configList = await GetCloudConfigurationList(param);
            let list = [{
                cloudConfigurationID: "-1",
                connectionType: 'local',
                connectionTypeName: 'Local',
                connectionName: 'Local',
                displayName: 'Local',
                defaultSelect: "true"
            }];
    
            if (configList.result && configList.result.length) {
                configList.result.forEach(item => {
                    list.push({
                        cloudConfigurationID: item.cloudConfigurationID + '',
                        connectionType: 'cloud',
                        connectionTypeName: item.connectionType == 1 ? 'Azure' : 'AWS',
                        connectionName: item.connectionName,
                        displayName: (item.connectionType == 1 ? 'Azure: ' : 'AWS: ') + item.connectionName,
                        defaultSelect: "false"
                    })
                })
            }
    
            setSourceList(list);
            setDestinationList(list.filter(item => item.connectionType == 'local'));
        }
    }, []);

    useEffect(async () => {
        if (destinationList?.length) {
            setDestinationID(destinationList[0].cloudConfigurationID);
            setDestination(parseInt(destinationList[0].cloudConfigurationID));
        }

    }, [destinationList]);

    const onFileChange = (event) => {
        // capture file into state
        setSelectedFileList([]);
        setFileTypeNotSelected(false);
        setFileCount(false);
        const allowedExtensions =  ['dcm', 'DCM'];
        const selectedFiles = [...event.target.files];
        const selectedFileListTemp = [...selectedFileList];
        if(selectedFiles.length > 100) {
            setFileCount(true);
        } else {
            selectedFiles.forEach((file) => {
                const fileExtension = file.name.split(".").pop();
                if(!allowedExtensions.includes(fileExtension)){
                    setFileTypeNotSelected(true);
                } else {
                    file.selected = true;
    
                    selectedFileListTemp.push(file);
                }
            });
            setSelectedAll(true);
            setSelectedFileList(selectedFileListTemp);
        }
    };

    const handleSelectChange = async (cloudConfigurationID, type) => {

        if(type == 'source') {
            setSource(cloudConfigurationID);
            setSelectedFileList([]);

            if (cloudConfigurationID >= 0) {

                setCloudSourceStorage(true);
                filterDestinationList('cloud');

                let param = {
                    organizationID: config.organizationID,
                    cloudConfigurationID: cloudConfigurationID
                };

                let fileList = await GetCloudStorageFilesList(param);

                if (fileList && fileList.result) {
                    if(fileList.result[0]) 
                        setIsFileCountMoreThanThousand(fileList.result[0].isFileCountMoreThanThousand);
                    setSelectedFileList(fileList.result.map(item => {
                        return {
                            name: item.fileName,
                            size: item.fileSize,
                            lastModifiedDate: item.lastModifiedDate,
                            selected: true
                        }
                    }));
                    setSelectedAll(true);
                }
            }
            else {
                setCloudSourceStorage(false);
                filterDestinationList('local');
            }

        } else{
            setDestination(cloudConfigurationID);
        }
    }

    const filterDestinationList = (connectionType) => {

        let list = sourceList.filter(item => item.connectionType == connectionType)
            .map(item => {
                return {
                    cloudConfigurationID: item.cloudConfigurationID,
                    connectionType: item.connectionType,
                    connectionTypeName: item.connectionTypeName,
                    connectionName: item.connectionName,
                    displayName: item.displayName,
                    defaultSelect: "false"
                }
            })

        if (list?.length)
            list[0].defaultSelect = 'true';
        setDestinationList(list);

    }
      
    return (
        <>
            <div className='tenants-main-container'>
                <div className='tenant-list-header'>
                    <p className='header-text'>Import Files</p>
                </div>
                <div className='tenant-container'>
                    <div className='input-file-container'>
                        <div className='config-input-container'>
                            <label className='import-files-label-text'>Source:</label>

                            <StorageSourceList onChange={(value) => handleSelectChange(value, 'source')} className='import-files-select select-text' listData={sourceList} selectedID={sourceID}>
                            </StorageSourceList>                    
                        </div>
                        <div className='file-input'>
                            {
                                !cloudSourceStorage && 
                                <div data-tip={convertTooltip} data-for="registerTip">
                                    <label className={disableButton ? 'custom-file-upload-disabled' : 'custom-file-upload'}>
                                        <input disabled={disableButton} type="file" onChange={(event) => onFileChange(event)} accept=".dcm" multiple='multiple"' />
                                        Select Files
                                    </label>
                                    <ReactToolTip 
                                        place='bottom'
                                        effect='solid'
                                        id="registerTip">
                                    </ReactToolTip>
                                </div>
                               
                            }
                        </div>
                    </div>
                    <div className='import-files-button-container'>
                        <div className='import-files-input-container'>
                            <label className='import-files-destination-label-text'>Destination:</label>
                            <StorageSourceList onChange={(value) => handleSelectChange(value, 'destination')} className='import-files-select-destination select-text' listData={destinationList} selectedID={destinationID}>
                            </StorageSourceList>
                        </div>
                    </div>
                </div>
                {fileTypeNotSupported ? <div className='file-not-supported'>Please select file with .dcm extension.</div> : ''}
                {fileCount ? <div className='file-not-supported'>Please select 100 files at a time only.</div> : ''}
                <ZuiDivider />
                <ImportFilesList
                    setUploadSuccess={props.setUploadSuccess}
                    setTotalFilesCount={props.setTotalFilesCount}
                    isFileCountMoreThanThousand={isFileCountMoreThanThousand}
                    setUploadedCount={props.setUploadedCount}
                    setDisableButton={setDisableButton}
                    setShowToast={props.setShowToast}
                    selectedFileList={selectedFileList}
                    fileProgress={fileProgress}
                    setFileProgress={setFileProgress}
                    cloudSourceStorage={cloudSourceStorage}
                    sourceID={source}
                    destinationID={destination}
                    setConvertTooltip={setConvertTooltip}
                    selectAll={selectAll}
                    setSelectedAll={setSelectedAll}
                />
            </div>
        </>
    )
}