import React, { useState, useEffect } from 'react';
import { SortingHeader } from '../../common/components/SortingHeader';
import '../styles/ImportFilesList.css';
import PlainTextData from '../../common/components/PlainTextData';
import { ZuiButton } from '@zeiss/zui-react';
import { useHistory } from 'react-router-dom';
import {uploadFileToBlob} from '../../azure-storage-blob';
import { ProgressBar } from 'react-bootstrap';
import { GetSASToken, ImportFile } from '../api/ImportFileAPI';
import { refreshAccessToken } from "../../utils/APIUtils";
import { config } from '../../utils/Config';
import { GetOrganizationLicenceDetails } from '../../tenants/api/TenantAPI';
import moment from 'moment';
import ReactToolTip from 'react-tooltip';
import {tokenData} from '../../router/Routes';

export default function ImportFilesList(props) {
    const history = useHistory();
    const [selectedFile, setSelectedFile] = useState(props.selectedFileList);
    const [sasToken, setSasToken] = useState('');
    const [disableConvertButton, setDisableConvertButton] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [convertTooltip, setConvertTooltip] = useState(props.convertTooltip);
    const [disableUpload, setDisableUpload] = useState(false);
    const [isFileCountMoreThanThousandTemp, setIsFileCountMoreThanThousandTemp] = useState(props.isFileCountMoreThanThousand);
    //const [licenceFeatureDetails, setLicenceFeatureDetails] = useState([]);
    //const [hideCheck, setHideCheck] = useState(false);
    /**
     * Function for selectedFile action on dashboard header for sorting the patinet data.
     * @param {String} sort - Unique column id for sorting By 
     * @param {Boolean} isOrderAscending  - Boolean value true(asc), false(desc)
     */

    useEffect(async ()  => {
        let token =  await refreshAccessToken();
        if (token && token.accessToken) {
            window.localStorage.setItem('token', token.accessToken);
        }
        let orgIdTemp = config && config.organizationID || tokenData.orgId || window.localStorage.setItem('organizationID');
        if(orgIdTemp) {
            let param =  {
                organizationID: orgIdTemp,
                duration: 8888
            };
            let fileData = await GetSASToken(param);
            if(fileData.result && fileData.result.saSToken) {
                setSasToken(fileData.result.saSToken);
            }
            console.log('getting datat');
            let licParam = {
                organizationID: orgIdTemp,
            }
            let responseData = await GetOrganizationLicenceDetails(licParam);
            if(responseData && responseData.licenceData && responseData.licenceData.organizationLicenses) {
                if(responseData.licenceData.organizationLicenses.length > 0) {
                    if(responseData.licenceData.organizationLicenses[0].expirationDate) {
                        console.log(responseData.licenceData.organizationLicenses[0]);
                        let now = moment().format('YYYY-MM-DD');
                        let expDate = responseData.licenceData.organizationLicenses[0].expirationDate;
                        expDate = moment(expDate).format('YYYY-MM-DD');
                        let result = moment(now).isAfter(moment(expDate));
                        if(result) {
                            props.setConvertTooltip('License validity is expired, Kindly contact Zeiss Support Team.');
                            setConvertTooltip('License validity is expired, Kindly contact Zeiss Support Team.');
                            props.setDisableButton(true);
                            setDisableUpload(true);
                        }
                    }
                }
            }

        } else {
            alert('organization details are not present');
        }
    }, []);

    useEffect(() => {
        setSelectedFile(props.selectedFileList);
    }, [props.selectedFileList]);

    useEffect(() => {
        setIsFileCountMoreThanThousandTemp(props.isFileCountMoreThanThousand);
    }, [props.isFileCountMoreThanThousand]);

    const sortFeatures = {
        sortHeader: true,
    };

    const handleSelectAll = () => {
        props.setSelectedAll(!props.selectAll);
        selectedFile.forEach((file) => {
            if(!props.selectAll) {
                setDisableConvertButton(false);
            } else {
                setDisableConvertButton(true);
            }
            file.selected = !props.selectAll;
        });
        setSelectedFile(selectedFile);
    }

    const selectFile = (event, file) => {
        file.selected = true;
        const value = event.target.checked;
        let fileTemp = [...selectedFile];
        let fileTempSelected = fileTemp.map((fileObj) => {
            if(fileObj.name === file.name) {
                fileObj.selected = value;
            }
            return fileObj;
        });
        let selectedTempFile = fileTempSelected.filter((fileObjTemp) => {
            if(fileObjTemp.selected) {
                return fileObjTemp;
            }
        });
        if(selectedFile.length === selectedTempFile.length) {
            props.setSelectedAll(true);
        } else {
            props.setSelectedAll(false);
        }
        if(selectedTempFile.length > 0) {
            setDisableConvertButton(false);
            //setHideCheck(true);
        } else {
            setDisableConvertButton(true);
        }
        setSelectedFile(fileTempSelected);
    }

    const getDateTimeInUTC = (fileList) => {
        let fileExtension = fileList.name.split(".").pop();
        let filenameText = fileList.name.replace(/\.[^/.]+$/, "");
        let m = new Date();
        let dateString =
            filenameText + "_" +
            m.getUTCFullYear() + "_" +
            ("0" + (m.getUTCMonth()+1)).slice(-2) + "_" +
            ("0" + m.getUTCDate()).slice(-2) + "_" +
            ("0" + m.getUTCHours()).slice(-2) + "_" +
            ("0" + m.getUTCMinutes()).slice(-2) + "_" +
            ("0" + m.getUTCSeconds()).slice(-2) +
            "." + fileExtension;
        return dateString;
    }

    const handleProcessClick = async () => {
        if (props.cloudSourceStorage) await handleProcessClickForCloud();
        else await handleProcessClickForLocal()

    }

    const handleProcessClickForLocal = async () => {
        props.setUploadSuccess(false);
        const importFileList = [];
        setDisableConvertButton(true);
        props.setDisableButton(true);
        setShowProgressBar(true);
        props.setShowToast(true);
        let temp = 0;
        let tempProgress;
        let finalSelectedFile = selectedFile.filter((obj) => {
            if(obj.selected) {
                return obj;
            }
        });
        props.setTotalFilesCount(finalSelectedFile.length);
        props.setUploadedCount(1);
        finalSelectedFile.forEach(async (fileList) => {
            if(fileList.selected) {
                const fileNameWithTime = getDateTimeInUTC(fileList);
                let fileNameObj = { fileName: `${config.organizationID}/Input/${fileNameWithTime}`};
                importFileList.push(fileNameObj)
                const myRenamedFile = new File([fileList], fileNameWithTime);
                let progress = await uploadFileToBlob(sasToken, myRenamedFile, config.organizationID);
                console.log(progress);
                fileList.uploaded = 1;
                if(fileList.uploaded === 1) {
                    temp = temp + fileList.uploaded;
                }
                tempProgress = Math.round((temp / finalSelectedFile.length) * 100);
                props.setUploadedCount(temp);
                props.setFileProgress(tempProgress);
                if(tempProgress == 100) {
                    props.setUploadSuccess(true);
                    setTimeout(() => {
                        props.setShowToast(false);
                    }, 10000);
                    setDisableConvertButton(false);
                    props.setDisableButton(false);
                    setShowProgressBar(false);
                    let param =  {
                        organizationID: config.organizationID,
                        sourceConnectionID: -1,
                        destinationConnectionID: -1,
                        createdBy: config.userId,
                        lstJobFile: importFileList
                    };
                    let fileData = await ImportFile(param);
                    if(fileData.responseCode === 200) {
                        window.localStorage.setItem('jobDisplayID', fileData.result.jobDisplayID);
                        history.push("/job-status");
                    }
                }
            }
        });
    };

    const handleProcessClickForCloud = async () => {
        let finalSelectedFile = selectedFile.filter((file) => file.selected)
            .map((file) => {
                return { fileName: file.name };
            });

        if (finalSelectedFile?.length) {
            let param = {
                organizationID: config.organizationID,
                sourceConnectionID: props.sourceID,
                destinationConnectionID: props.destinationID,
                createdBy: config.userId,
                lstJobFile: finalSelectedFile,
                isFileCountMoreThanThousandTemp: isFileCountMoreThanThousandTemp
            };

            let fileData = await ImportFile(param);

            if (fileData.responseCode === 200) {
                window.localStorage.setItem('jobDisplayID', fileData.result.jobDisplayID);
                history.push("/job-status");
            }
        }
    }

    return (
        <div>
            <div className='import-files-main-container'>
                <p>Select Files</p>
                <table className='tabel import-file-list-table' aria-labelledby='tabelLabel'>
                    <thead className='importfile-list-info-header'>
                        <tr>
                            <td colSpan='1' className='checkbox-header'>
                                <input type='checkbox' disabled={isFileCountMoreThanThousandTemp} checked={props.selectAll} onClick={handleSelectAll}/>
                            </td>
                            <SortingHeader customCss='crd-table-patient-id source-files-header' {...sortFeatures} id='patientID' title='Source Files' />
                        </tr>
                    </thead>
                    <tbody className='input-file-tbody'>
                        {selectedFile.length === 0 ? <tr style={{display: 'grid'}}><td colSpan={10}><p className='files-not-selected'>No file selected</p></td></tr> : ''}
                        {(selectedFile).map((selectedFile) => {
                            return (
                                <React.Fragment key={`tr-${selectedFile.name}`}>
                                    <tr className="crd-table-row">
                                        <td className='crd-table-caret-icon'>
                                            <input disabled={isFileCountMoreThanThousandTemp} type='checkbox' checked={selectedFile.selected} onChange={(event) => selectFile(event, selectedFile)} defaultChecked={selectedFile.selected} />
                                        </td>
                                        <td className='crd-table-patient-id filename-list'>
                                            <PlainTextData value={selectedFile.name} />
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            
            {selectedFile.length > 0 && !disableConvertButton ?
                <div className='import-file-button-container' data-tip={convertTooltip} data-for="registerTip">
                    <ZuiButton  disabled={disableConvertButton || disableUpload}  emphasis='active-primary' onClick={handleProcessClick}>Convert</ZuiButton>
                    <ReactToolTip 
                        place='bottom'
                        effect='solid'
                        id="registerTip">
                    </ReactToolTip>
                </div> 
                : ''}
            {showProgressBar ?
                <ProgressBar className='progres-bar' label={props.fileProgress} animated now={props.fileProgress} /> : ''}
            
        </div>
    );
}