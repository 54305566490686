import React, { useState,useEffect, useRef } from 'react';
import { SortingHeader } from '../../common/components/SortingHeader';
import '../styles/TenantList.css';
import PlainTextData from '../../common/components/PlainTextData';
import { ZuiIconEditEditEdit, ZuiIconDelete } from '@zeiss/zui-react';
import { TenantAPI } from '../api/TenantAPI';
import ReactToolTip from 'react-tooltip';

export default function TenantList(props) {
    const [allOrganizationsData, setOrganizationsData] = useState(props.organizationsData);
    const [sortBy, setSortBy] = useState('organizationName');
    const [disableSort, setDisableSort] = useState(false);
    const [sortAscending, setsortAscending] = useState(true);

    const arBodyContentRef = useRef(null);

    const onScroll = () => {
        props.setScrollLoading(false);
        let scrollBottom = arBodyContentRef.current.scrollHeight - arBodyContentRef.current.scrollTop - arBodyContentRef.current.clientHeight;
        scrollBottom = Math.floor(Math.abs(scrollBottom)) <= 0;
        if (scrollBottom)  {
            props.setScrollLoading(true);
            loadMoreExams();
        }
    }

    function loadMoreExams() {
        if (allOrganizationsData.length >= props.totalResults - 1) {
            props.setScrollLoading(false);
            return;
        } 
        if(allOrganizationsData.length == 0) return;
        props.setPage(props.page + 1);
        (async () => { 
            await props.fetchMoreListItems(props.page + 1); 
        })();
    }

    useEffect(() => {
        setOrganizationsData(props.organizationsData);
    }, [props.organizationsData]);

    /**
     * Function for user action on table header for sorting the tenants data.
     * @param {String} sort - Unique column id for sorting By 
     * @param {Boolean} isOrderAscending  - Boolean value true(asc), false(desc)
     */
    const onHeaderClick = async (sort, isOrderAscending) => {
        let order = isOrderAscending ? 'asc' : 'desc';
        let param = { 
            sort, 
            order,
            pageNo: props.page,
            pageSize: 60,
        };
        
        setDisableSort(true);
        let organizationData = await TenantAPI(param);
        if(organizationData.responseCode === 200) {
            let data = props.filterServiceAdmin(organizationData.organizations)
            setOrganizationsData(data);
            setSortBy(sort);
            setsortAscending(isOrderAscending);
            setDisableSort(false);
        }
    }

    const sortFeatures = {
        sortHeader: true,
        sortBy,
        sortAscending,
        disable: disableSort,
        onHeaderClick: (id, order) => onHeaderClick(id, order)
    };

    return (
        <div>
            <table className='tabel tenant-list-table' aria-labelledby='tabelLabel'>
                <thead className='tenants-list-info-header'>
                    <tr>
                        <SortingHeader customCss='crd-table-patient-id' {...sortFeatures} id='organizationName' title='Organization' />
                        <SortingHeader customCss='crd-table-patient-name' {...sortFeatures} id='description' title='Description' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='name' title='Contact' />
                        <SortingHeader customCss='crd-table-eyes-monitored' {...sortFeatures} id='emailAddress' title='Email' />
                        <SortingHeader customCss='job-status-table-edit-icon-size' sortHeader={false}  title='Action' />
                    </tr>
                </thead>
                <tbody  ref={arBodyContentRef} onScroll={onScroll} className='config-body'>
                    {allOrganizationsData.length > 0 ? (allOrganizationsData).map((organization) => {
                        const orgName = (organization.dbStatus === 0 || organization.dbStatus === 1) ?  (organization.organizationName + '*') : organization.organizationName;
                        return (
                            <React.Fragment key={`tr-${organization.organizationID}`}>
                                <tr className="crd-table-row job-row">
                                    <td className='crd-table-patient-id' >
                                        <PlainTextData value={orgName} subValue={orgName} />
                                    </td>
                                    <td className='crd-table-patient-name' >
                                        <PlainTextData value={organization.description} />
                                    </td>
                                    <td className='crd-table-diagnosis' >
                                        <PlainTextData value={organization.name} />
                                    </td>
                                    <td className='crd-table-uses-past-four-weeks' >
                                        <PlainTextData value={organization.emailAddress} />
                                    </td>
                                    <td className='crd-table-edit-icon-size' >
                                        <div style={{display: 'flex'}}>
                                            <div className="edit-action" data-tip='Edit Organization' data-for="registerTip">
                                                <ZuiIconEditEditEdit  onClick={() => props.onClick(organization, 'Edit')} />
                                            </div>
                                            <div data-tip='Delete Organization' data-for="registerTip">
                                                <ZuiIconDelete  onClick={() => props.handleDeleteModalShow(organization)} />
                                            </div>
                                            <ReactToolTip 
                                                place='bottom'
                                                effect='solid'
                                                id="registerTip">
                                            </ReactToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    }) : ''}
                    {allOrganizationsData.length == 0 && 
                        <div className='no-data'>
                            <span>No Organization found.</span>
                        </div>
                    }
                </tbody>
            </table>
            {props.scrollLoading && 
                <div className='loading-text'>
                    Loading data...
                </div>
            }
        </div>
    );
}