import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function GetAllJobsAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        sortColumn: param.sort,
        sortOrder: param.order,
        organizationID: param.organizationID,
        accountID: param.accountID,
        jobStatusID: param.jobStatusID
    }

    await APIUtils('/api/Job/GetAllJobs').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.jobs = result.data.result;
            responseData.totalResults = result.data.totalResults;
            responseData.actualResults = result.data.actualResults;
            appInsights.trackTrace({ message: 'GetAllJobsAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetAllJobsAPI' } });
            }
        });
    return responseData;
}

export async function GetJobStatusAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: 1,
        pageSize: 10000,
        sortColumn: param.sort,
        sortOrder: param.order,
        organizationID: param.organizationID
    }

    await APIUtils('/api/Job/GetJobStatusLookup').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.jobstatus = result.data.result;
            appInsights.trackTrace({ message: 'GetJobStatusLookup', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetJobStatusLookup' } });
            }
        });
    return responseData;
}

export async function GetJobAllFiles(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        organizationID: param.organizationID,
        sortColumn: param.sort,
        sortOrder: param.order,
        jobID: param.jobID,
        fileStatusID: param.fileStatusID,
        accountID: param.accountID,
    }

    await APIUtils('/api/Job/GetJobAllFiles').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.totalResults = result.data.totalResults;
            responseData.jobs = result.data.result;
            appInsights.trackTrace({ message: 'GetJobAllFiles', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetJobAllFiles' } });
            }
        });
    return responseData;
}

export async function GetFileStatusLookup(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: 1,
        pageSize: 10000,
        sortColumn: param.sort,
        sortOrder: param.order,
        organizationID: param.organizationID
    }

    await APIUtils('/api/Job/GetFileStatusLookup').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.jobstatus = result.data.result;
            appInsights.trackTrace({ message: 'GetFileStatusLookup', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetFileStatusLookup' } });
            }
        });
    return responseData;
}

export async function cancelJobAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        jobID: param.jobID,
        modifiedBy: param.modifiedBy
    }

    await APIUtils('/api/Job/CancelJob').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.jobs = result.data.result;
            appInsights.trackTrace({ message: 'cancelJobAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'cancelJobAPI' } });
            }
        });
    return responseData;
}

export async function downloadAllAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
        jobID: param.jobID,
    }

    await APIUtils('api/Download/DownloadJob').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.jobs = result.data;
            appInsights.trackTrace({ message: 'DownloadJob', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'DownloadJob' } });
            }
        });
    return responseData;
}
