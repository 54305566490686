import React, { useState, useEffect, useRef } from 'react';
import { SortingHeader } from '../../common/components/SortingHeader';
import '../styles/JobStatusList.css';
import PlainTextData from '../../common/components/PlainTextData';
import { ZuiIconFileFileReportOverview, ZuiIconDownload } from '@zeiss/zui-react';
import ReactToolTip from 'react-tooltip';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';

export default function JobStatusList(props) {
    const [allJobsData, setAllJobsData] = useState(props.jobsData);
    const [disableSort, setDisableSort] = useState(false);
    const [sortAscending, setsortAscending] = useState(false);
    const [sortBy, setSortBy] = useState('jobDisplayID');
    const arBodyContentRef = useRef(null);
    
    const onScroll = () => {
        props.setScrollLoading(false);
        let scrollBottom = arBodyContentRef.current.scrollHeight - arBodyContentRef.current.scrollTop - arBodyContentRef.current.clientHeight;
        scrollBottom = Math.floor(Math.abs(scrollBottom)) <= 0;
        if (scrollBottom)  {
            props.setScrollLoading(true);
            loadMoreExams();
        }
    }

    function loadMoreExams() {
        if (allJobsData.length >= props.totalResults) {
            props.setScrollLoading(false);
            return;
        } 
        if(allJobsData.length == 0) return;
        props.setPage(props.page + 1);
        (async () => { 
            await props.fetchMoreListItems(props.page + 1); 
            //props.setScrollLoading(false);
        })();
    }

    useEffect(() => {
        setAllJobsData(props.jobsData);
    }, [props.jobsData]);

    /**
     * Function for user action on table header for sorting the tenants data.
     * @param {String} sort - Unique column id for sorting By 
     * @param {Boolean} isOrderAscending  - Boolean value true(asc), false(desc)
     */
    const onHeaderClick = async (sort, isOrderAscending) => {
        let order = isOrderAscending ? 'asc' : 'desc';
        setDisableSort(true);
        props.getJobsByUserAndJobs(props.selectedOrganization, props.selectedUser, props.selectedJobStatus, 1, props.page*60, 'UI', sort, order );
        setSortBy(sort);
        setsortAscending(isOrderAscending);
        props.setJobSortBy(sort);
        props.setJobSortAscending(order);
        setDisableSort(false);
    }

    const sortFeatures = {
        sortHeader: true,
        sortBy,
        sortAscending,
        disable: disableSort,
        onHeaderClick: (id, order) => onHeaderClick(id, order)
    };

    const getDateTimeInFormat = (date) => {
        if(date) {
            const format = "MM-DD-YYYY HH:mm:ss"
            let dateTemp = new Date(date);
            let dateTimeTemp = moment(dateTemp).format(format);
            return dateTimeTemp;
        } else {
            return '';
        }
    };

    return (
        <div className='ar-body-content'>
            <table className='tabel job-status-list-table' aria-labelledby='tabelLabel'>
                <thead className='job-status-list-info-header'>
                    <tr>
                        <SortingHeader customCss='crd-table-patient-id' {...sortFeatures} id='jobDisplayID' title='Job-ID' />
                        <SortingHeader customCss='crd-table-patient-name' {...sortFeatures} id='jobStartTime' title='Start Date-Time' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='jobEndTime' title='End Date-Time' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='destinationConnectionID' title='Job Type' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='jobStatus' title='Status' />
                        <SortingHeader customCss='job-status-table-edit-icon-size' sortHeader={false} title='Action' />
                    </tr>
                </thead>
                <tbody ref={arBodyContentRef} onScroll={onScroll} className='job-body'>
                    {allJobsData.length > 0 ? (allJobsData).map((job) => {
                        let cloudType = job.destinationConnectionID > -1 ? 'Cloud' : 'Local';
                        return (
                            <React.Fragment key={`tr-${job.jobDisplayID}`}>
                                <tr className="crd-table-row job-row">
                                    <td className='crd-table-patient-id'>
                                        <PlainTextData value={job.jobDisplayID} subValue={job.jobDisplayID} />
                                    </td>
                                    <td className='crd-table-patient-name'>
                                        <PlainTextData value={getDateTimeInFormat(job.jobStartTime)} subValue={getDateTimeInFormat(job.jobStartTime)} />
                                    </td>
                                    <td className='crd-table-diagnosis'>
                                        <PlainTextData value={getDateTimeInFormat(job.jobEndTime)} subValue={getDateTimeInFormat(job.jobEndTime)} />
                                    </td>
                                    <td className='crd-table-diagnosis'>
                                        <PlainTextData value={cloudType} subValue={cloudType} />
                                    </td>
                                    <td className='crd-table-diagnosis'>
                                        {job.jobStatus === 'Processing' ? 
                                            <div>
                                                <ProgressBar className='progres-bar' label={job.percentage} animated now={job.percentage} /> 
                                                <PlainTextData value={job.jobStatus} subValue={job.jobStatus}/>
                                            </div>
                                            : <PlainTextData value={job.jobStatus} subValue={job.jobStatus}/>}
                                    </td>
                                    <td className='job-status-table-edit-icon-size'>
                                        <div style={{display: 'flex'}}>
                                            <div className='job-status-view' data-tip='View Job Details' data-for="registerTip">
                                                <ZuiIconFileFileReportOverview  onClick={() => props.onClick(job)} />
                                            </div>
                                            <div className={job.downloaded ? 'disable-download-action' : ''} data-tip={job.downloadToolTip} data-for="registerTip">
                                                <ZuiIconDownload disable={job.downloaded} onClick={() => props.handleDownloadAll(job)} />
                                            </div>
                                            <ReactToolTip 
                                                place='bottom'
                                                effect='solid'
                                                id="registerTip">
                                            </ReactToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    }) : ''}
                    {allJobsData.length == 0 && !props.loadingJobData &&
                        <div className='no-data'>
                            <span>No jobs found.</span>
                        </div>
                    }
                    {props.loadingJobData &&
                        <div className='no-data'>
                            <Spinner animation="border" variant="secondary"></Spinner>
                            {/* <span>Loading data..</span> */}
                        </div>
                    }
                </tbody>
            </table>
            {props.scrollLoading && 
                <div className='loading-text'>
                    Loading data...
                </div>
            }
        </div>
    );
}