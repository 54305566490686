import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export async function GetOrganizationAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: 1,
        pageSize: 10000,
        sortColumn: param.sort,
        sortOrder: param.order
    }

    await APIUtils('Organization/GetOrganization').postCall(responseData)
        .then((result) => {
            console.log('here', result.data);
            responseData.responseCode = result.status;
            responseData.organizations = result.data.result;
            appInsights.trackTrace({ message: 'GetOrganizationAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetOrganizationAPI' } });
            }
        });
    return responseData;
}

export async function GetUsersAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        sortColumn: param.sort,
        sortOrder: param.order,
        organizationID: param.organizationID
    }

    await APIUtils('User/GetUsers').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.totalResults = result.data.totalResults;
            responseData.users = result.data.result;
            appInsights.trackTrace({ message: 'GetUsersAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetUsersAPI' } });
            }
        });
    return responseData;
}

export async function GetAndUpdateUsersAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID
    };

    await APIUtils('User/GetAndUpdateUsersState').postCall(responseData)
        .then((result) => {
            responseData.responseCode = result.status;
            responseData.organizations = result.data.result;
            appInsights.trackTrace({ message: 'GetAndUpdateUsersAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetAndUpdateUsersAPI' } });
            }
        });
    return responseData;
}

export async function CreateUserAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        userID: param.userID,
        roleID: param.roleID,
        firstName: param.firstName,
        lastName: param.lastName,
        emailAddress: param.emailAddress,
        organizationID: param.organizationID,
        organizationName: param.organizationName,
        createdBy: param.createdBy,
        modifiedBy: param.modifiedBy,
        zeissRefAccountID: param.zeissRefAccountID,
        isReActivate: param.isReActivate
    };

    await APIUtils('User/CreateUser').postCall(responseData)
        .then((result) => {
            responseData.data = result.data;
            responseData.responseCode = result.status;
            responseData.message= result.message;
            appInsights.trackTrace({ message: 'CreateUserAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'CreateUserAPI' } });
            }
        });
    return responseData;
}

export async function DeleteUserAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        userID: param.userID,
        organizationID: param.organizationID,
        emailAddress: param.emailAddress,
        modifiedBy: param.modifiedBy,
        zeissRefAccountID: param.zeissRefAccountID
    };

    await APIUtils('User/DeleteUser').postCall(responseData)
        .then((result) => {
            responseData.data = result.data;
            responseData.responseCode = result.status;
            responseData.message= result.message;
            appInsights.trackTrace({ message: 'DeleteUserAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'DeleteUserAPI' } });
            }
        });
    return responseData;
}
