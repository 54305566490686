import { APIUtils } from '../../utils/APIUtils';
import { getAppInsights } from '../../TelemetryService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';


export async function TenantAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        pageNo: param.pageNo,
        pageSize: param.pageSize,
        sortColumn: param.sort,
        sortOrder: param.order
    }

    await APIUtils('Organization/GetOrganization').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.totalResults = result.data.totalResults;
            responseData.organizations = result.data.result;
            appInsights.trackTrace({ message: 'TenantAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'TenantAPI' } });
            }
        });
    return responseData;
}

export async function GetAndUpdateOrganizationAPI() {
    let appInsights = getAppInsights();
    let responseData = {};

    await APIUtils('Organization/GetAndUpdateOrganizationsDBStatus').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.organizations = result.data.result;
            appInsights.trackTrace({ message: 'GetAndUpdateOrganizationAPI', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetAndUpdateOrganizationAPI' } });
            }
        });
    return responseData;
}

export async function CreateOrganizationAPI(param, mode) {
    let appInsights = getAppInsights();
    let responseData;
    if(mode === 'Create') {
        responseData = {
            organizationName: param.organizationName,
            description: param.description,
            contactFirstName: param.contactFirstName,
            contactLastName: param.contactLastName,
            emailAddress: param.emailAddress,
            organizationID: param.organizationID
        };
    } else {
        responseData = {
            organizationName: param.organizationName,
            description: param.description,
            contactFirstName: param.contactFirstName,
            contactLastName: param.contactLastName,
            emailAddress: param.emailAddress,
            organizationID: param.organizationID,
            zeissRefOrganizationID: param.zeissRefOrganizationID,
            organizationLicenses: param.organizationLicenses
        };
    }

    await APIUtils('Organization/CreateOrganization').postCall(responseData)
        .then((result) => {
            responseData.data = result.data;
            responseData.responseCode = result.status;
            appInsights.trackTrace({ message: 'CreateOrganization', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'CreateOrganization' } });
            }
        });
    return responseData;
}

export async function DeleteOrganizationAPI(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationName: param.organizationName,
        organizationID: param.organizationID,
        zeissRefOrganizationID: param.zeissRefOrganizationID,
    };

    await APIUtils('Organization/DeleteOrganization').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.data = result.data;
            responseData.responseCode = result.status;
            responseData.message= result.message;
            appInsights.trackTrace({ message: 'DeleteOrganization', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'DeleteOrganizationAPI' } });
            }
        });
    return responseData;
}

export async function GetOrganizationLicenceDetails(param) {
    let appInsights = getAppInsights();
    let responseData = {
        organizationID: param.organizationID,
    };

    await APIUtils('Organization/GetOrganizationLicenceDetails').postCall(responseData)
        .then((result) => {
            console.log(result);
            responseData.responseCode = result.status;
            responseData.statusCode = result.statusCode;
            responseData.licenceData = result.data.result;
            appInsights.trackTrace({ message: 'GetOrganizationLicenceDetails', properties: { source: 'web-app', response: result } });
        })
        .catch((err) => {
            if (err.response) {
                console.log(err);
                responseData.responseCode = err.response.status;
                responseData.data = err.response.data;
                appInsights.trackException({ error: err, severityLevel: SeverityLevel.Error, properties: { source: 'web-app', name: 'GetOrganizationLicenceDetails' } });
            }
        });
    return responseData;
}
