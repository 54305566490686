import React, { useState, useEffect, useRef } from 'react';
import { SortingHeader } from '../../common/components/SortingHeader';
import '../styles/ConfigurationList.css';
import PlainTextData from '../../common/components/PlainTextData';
import { ZuiIconEditEditEdit, ZuiIconDelete } from '@zeiss/zui-react';
import ReactToolTip from 'react-tooltip';
import { GetConfigurationAPI } from '../api/ConfigurationAPI';
import { config } from '../../utils/Config';

export default function ConfigurationList(props) {
    const [allConfigurationData, setAllConfigurationData] = useState(props.configurationData);
    const [sortBy, setSortBy] = useState('connectionName');
    const [disableSort, setDisableSort] = useState(false);
    const [sortAscending, setsortAscending] = useState(true);
    const arBodyContentRef = useRef(null);

    const onScroll = () => {
        props.setScrollLoading(false);
        let scrollBottom = arBodyContentRef.current.scrollHeight - arBodyContentRef.current.scrollTop - arBodyContentRef.current.clientHeight;
        scrollBottom = Math.floor(Math.abs(scrollBottom)) <= 0;
        if (scrollBottom)  {
            props.setScrollLoading(true);
            loadMoreExams();
        }
    }

    function loadMoreExams() {
        if (allConfigurationData.length >= props.totalResults) {
            props.setScrollLoading(false);
            return;
        } 
        if(allConfigurationData.length == 0) return;
        props.setPage(props.page + 1);
        (async () => { 
            await props.fetchMoreListItems(props.page + 1); 
        })();
    }

    useEffect(() => {
        setAllConfigurationData(props.configurationData);
    }, [props.configurationData]);

    /**
     * Function for user action on table header for sorting the tenants data.
     * @param {String} sort - Unique column id for sorting By 
     * @param {Boolean} isOrderAscending  - Boolean value true(asc), false(desc)
     */
    const onHeaderClick = async (sort, isOrderAscending) => {
        let order = isOrderAscending ? 'asc' : 'desc';
        let param = { 
            sort, 
            order,
            organizationID: config.organizationID,
            pageNo: props.page,
            pageSize: 60,
        };
        
        setDisableSort(true);
        let configData = await GetConfigurationAPI(param);
        if(configData.responseCode === 200) {
            setAllConfigurationData(configData.configurations);
            setSortBy(sort);
            setsortAscending(isOrderAscending);
            setDisableSort(false);
        }
    }

    const sortFeatures = {
        sortHeader: true,
        sortBy,
        sortAscending,
        disable: disableSort,
        onHeaderClick: (id, order) => onHeaderClick(id, order)
    };

    return (
        <div ref={arBodyContentRef} onScroll={onScroll} className='ar-body-content'>
            <table className='tabel tenant-list-table' aria-labelledby='tabelLabel'>
                <thead className='tenants-list-info-header'>
                    <tr>
                        <SortingHeader customCss='crd-table-patient-id' {...sortFeatures} id='connectionName' title='Connection Name' />
                        <SortingHeader customCss='crd-table-diagnosis' {...sortFeatures} id='connectionType' title='Cloud Name' />
                        <SortingHeader customCss='job-status-table-edit-icon-size' sortHeader={false} title='Action' />
                    </tr>
                </thead>
                <tbody ref={arBodyContentRef} onScroll={onScroll} className='config-body'>
                    {allConfigurationData.length > 0 ? (allConfigurationData).map((connection) => {
                        const connectionName = connection.connectionType == 2 ? 'AWS' : 'Azure';
                        return (
                            <React.Fragment key={`tr-${connection.patientID}`}>
                                <tr className="crd-table-row job-row">
                                    <td className='crd-table-patient-id'>
                                        <PlainTextData value={connection.connectionName} subValue={connection.connectionName} />
                                    </td>
                                    <td className='crd-table-diagnosis'>
                                        <PlainTextData value={connectionName} subValue={connectionName}/>
                                    </td>
                                    <td className='crd-table-edit-icon-size'>
                                        <div style={{display: 'flex'}}>
                                            <div className="edit-action" data-tip='Edit Cloud Connection' data-for="registerTip">
                                                <ZuiIconEditEditEdit  onClick={() => props.onClick(connection, 'Edit')} />
                                            </div>
                                            <div data-tip='Delete Cloud Connection' data-for="registerTip">
                                                <ZuiIconDelete onClick={() => props.handleDeleteModalShow(connection)} />
                                            </div>
                                            <ReactToolTip 
                                                place='bottom'
                                                effect='solid'
                                                id="registerTip">
                                            </ReactToolTip>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    }) : ''}
                    {allConfigurationData.length == 0 && 
                        <div className='no-data'>
                            <span>No connection found.</span>
                        </div>
                    }
                </tbody>
            </table>
            
            {props.scrollLoading && 
                <div className='loading-text'>
                    Loading data...
                </div>
            }
        </div>
    );
}