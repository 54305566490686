import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from '../src/router/index';
import 'bootstrap/dist/css/bootstrap.css';

import "@zeiss/zui"
import { themeBase, registerTheme, registerTypefaces, setAppTheme } from "@zeiss/zui-theme-base"
import { themeLight } from "@zeiss/zui-theme-light"
import { themeDark } from "@zeiss/zui-theme-dark";
// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

registerTheme(themeBase)
registerTheme(themeLight)
registerTheme(themeDark)

setAppTheme("light")

registerTypefaces("/fonts")

const rootElement = document.getElementById('root') || document.createElement('div');

ReactDOM.render(<Router pca={msalInstance} />, rootElement);
