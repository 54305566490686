import React from "react";
import { Modal } from 'react-bootstrap';
import { ZuiButton} from "@zeiss/zui-react"
import { Button } from 'react-bootstrap';

const SessionTimeoutDialog = (props) => {

    return (
        <>
            <Modal show={props.open} onHide={props.onContinue} backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Session Timeout</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='tenant-container'>
                        <p>The current session is about to expire in {props.countdown} minutes.</p>
                        <p>Would you like to continue the session ?</p>
                    </div> 
                </Modal.Body>
                <Modal.Footer>
                    <Button className="timeout-button" variant="secondary"  onClick={props.onContinue}>
                        <span style={{marginLeft: 10}}>Continue Session</span>
                    </Button>
                    <ZuiButton emphasis='active-primary' onClick={props.onLogout}>Logout</ZuiButton>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default SessionTimeoutDialog;