import React from 'react';
import '../styles/Report.css';
import { ZuiSelectButton, ZuiDivider, ZuiButton } from "@zeiss/zui-react";


export const Report = () => {
    
    return (
        <>
            <div className='tenants-main-container'>
                <div className='tenant-list-header'>
                    <p className='header-text'>Report</p>
                </div>
                <div className='reports-date-container'>
                    <div className='drop-down'>
                        <span>Organisation : </span>
                        <div>
                            <ZuiSelectButton size='s' className='select'>
                                Select Organisation
                            </ZuiSelectButton>
                        </div>
                    </div>
                    <div className='drop-down'>
                        <span>User :</span> 
                        <div>
                            <ZuiSelectButton size='s' className='select'>
                                Select User
                            </ZuiSelectButton>
                        </div>
                    </div>
                    <div>
                        <span>Start Date :</span>
                    </div>
                    <div>
                        <span>End Date :</span>
                    </div>
                    <div>
                        <ZuiButton emphasis='active-primary'>Apply</ZuiButton>
                    </div>
                </div>
                <ZuiDivider />
                <div className='reports-tile-container'>
                    <b>Organization</b> :  Zeiss <b>Start Date</b>: May 1,2021  <b>End Date</b> : May 1,2021 
                </div>
            </div>
        </>
    )
}