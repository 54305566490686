import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_ZeissIdNormalSignIn"
        // forgotPassword: "b2c_1_reset",
        // editProfile: "b2c_1_edit_profile"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://id-ip-test.zeiss.com/accounts-test.zeiss.com/B2C_1A_ZeissIdNormalSignIn",
        }
        // ,
        // forgotPassword: {
        //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_reset",
        // },
        // editProfile: {
        //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile"
        // }
    },
    authorityDomain: "id-ip-test.zeiss.com"
}
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "040d1afc-e2aa-4f60-8efe-257be16c6877",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
        authorityMetadata: `{
            "issuer":"https://b2ctest.zeiss.com/7c8969d1-3d1c-460c-a32f-879d8941255f/v2.0/",
            "authorization_endpoint":"https://id-ip-test.zeiss.com/OAuth/Authorize?p=B2C_1A_ZeissIdNormalSignIn",
            "token_endpoint":"https://id-ip-test.zeiss.com/OAuth/token?p=B2C_1A_ZeissIdNormalSignIn",
            "end_session_endpoint":"https://id-ip-test.zeiss.com/oauth/logout?p=B2C_1A_ZeissIdNormalSignIn",
            "jwks_uri":"https://b2ctest.zeiss.com/b2ctest.zeiss.com/discovery/v2.0/keys?p=b2c_1a_zeissidnormalsignin",
            "response_modes_supported":["query","fragment","form_post"],
            "response_types_supported":["code","code id_token","code token","code id_token token","id_token","id_token token","token","token id_token"],
            "scopes_supported":["openid"],
            "subject_types_supported":["pairwise"],
            "id_token_signing_alg_values_supported":["RS256"],
            "token_endpoint_auth_methods_supported":["client_secret_post","client_secret_basic"],
            "claims_supported":["oid","sub","email","sub","ZeissIdBase","ZeissIdContact","ZeissIdOrganisation","ZeissIdApplication","ZeissIdCustomFields","ZeissIdAgreements","getMoreClaims","iss","iat","exp","aud","acr","nonce","auth_time"]}`,
        redirectUri: process.env.REACT_APP_BASE_URL,
        postLogoutRedirectUri: process.env.REACT_APP_BASE_URL
    },
    cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                case LogLevel.Error:
                    console.error(message);
                    return;
                case LogLevel.Info:
                    console.info(message);
                    return;
                case LogLevel.Verbose:
                    console.debug(message);
                    return;
                case LogLevel.Warning:
                    console.warn(message);
                    return;
                default:
                    return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid offline_access 040d1afc-e2aa-4f60-8efe-257be16c6877"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
    // graphMeEndpoint: "https://id-test.zeiss.com/profile/index"
};

